import React, { useState, useEffect } from 'react';
import { FaEdit, FaTrashAlt , FaPlus, FaUndo, FaRedo, FaMagic } from 'react-icons/fa';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import high_depth from '../../assets/high_depth.svg';
import normal_depth from '../../assets/normal_depth.svg';
import high_depth_grey from '../../assets/high_grey.svg';
import normal_depth_grey from '../../assets/normal_grey.svg';
import { ThreeDots } from 'react-loader-spinner';
import ProgressBar from '../ProgressBar';
import { generateSingleTopicQuestions } from '../ApiUtils';

const DiscussionGuideStep = ({ discussionGuide, setDiscussionGuide, isGeneratingDiscussionGuide, progress, progressStatus, getAccessTokenSilently, getIdTokenClaims, discussionGuideErrors = {},
   selectedTopicId, setSelectedTopicId, removeDiscussionGuideError  }) => {
  

  const [expectedInterviewTime, setExpectedInterviewTime] = useState("Under 5 mins");

  const [editIndex, setEditIndex] = useState(null);
  const [loadingQuestions, setLoadingQuestions] = useState(false);

  const calculateInterviewTime = (numTopics) => {
    if (numTopics <= 3) return "Under 5 mins";
    if (numTopics <= 6) return "5-10 mins";
    if (numTopics <= 9) return "10-15 mins";
    if (numTopics <= 12) return "15-20 mins";
    if (numTopics <= 15) return "20-25 mins";
    return "25+ mins";
  };
  

  
  const [questions, setQuestions] = useState(
    selectedTopicId && Array.isArray(discussionGuide)
      ? discussionGuide.find((topic) => topic.topic_id === selectedTopicId)?.questions || []
      : []
  );
  

  const [history, setHistory] = useState([]);
  const [redoStack, setRedoStack] = useState([]);

  const handleTopicClick = (id) => {
    setSelectedTopicId(id);
  };

  const pushToHistory = (prevState) => {
    setHistory((oldHistory) => [...oldHistory, JSON.parse(JSON.stringify(prevState))]);
  };

  const handleUndo = () => {
    if (history.length > 0) {
      const lastState = history[history.length - 1];
      setRedoStack((oldRedoStack) => [...oldRedoStack, JSON.parse(JSON.stringify(discussionGuide))]);
      setDiscussionGuide(lastState);
      setHistory(history.slice(0, history.length - 1));
      
      const selectedTopic = lastState.find((topic) => topic.topic_id === selectedTopicId);
      if (selectedTopic) {
        setQuestions(selectedTopic.questions);
      } else {
        setSelectedTopicId(lastState.length > 0 ? lastState[0].topic_id : null);
        const newSelected = lastState.find((t) => t.topic_id === lastState[0]?.topic_id);
        setQuestions(newSelected ? newSelected.questions : []);
      }
    }
  };


  const handleRedo = () => {
    if (redoStack.length > 0) {
      const lastRedoState = redoStack[redoStack.length - 1];
      setHistory((oldHistory) => [...oldHistory, JSON.parse(JSON.stringify(discussionGuide))]);
      setDiscussionGuide(lastRedoState);
      setRedoStack(redoStack.slice(0, redoStack.length - 1));
      
      // Ensure questions and selected topic sync
      const selectedTopic = lastRedoState.find((topic) => topic.topic_id === selectedTopicId);
      if (selectedTopic) {
        setQuestions(selectedTopic.questions);
      } else {
        setSelectedTopicId(lastRedoState.length > 0 ? lastRedoState[0].topic_id : null);
        const newSelected = lastRedoState.find((t) => t.topic_id === lastRedoState[0]?.topic_id);
        setQuestions(newSelected ? newSelected.questions : []);
      }
    }
  };
  
  

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    pushToHistory(discussionGuide);
    const reorderedQuestions = Array.from(questions);
    const [movedQuestion] = reorderedQuestions.splice(result.source.index, 1);
    reorderedQuestions.splice(result.destination.index, 0, movedQuestion);

    reorderedQuestions.forEach((q, newIndex) => {
      q.order = newIndex;
    });

    setQuestions(reorderedQuestions);

    setDiscussionGuide((prevTopics) =>
      prevTopics.map((topic) =>
        topic.topic_id === selectedTopicId ? { ...topic, questions: reorderedQuestions } : topic
      )
    );
  };

  const handleQuestionChange = (index, value) => {
    pushToHistory(discussionGuide);
    const updatedQuestions = [...questions];
    updatedQuestions[index].question = value;
    updatedQuestions[index].question_label = value;
    // Update local questions
    setQuestions(updatedQuestions);
    // Update discussionGuide
    setDiscussionGuide((prevTopics) =>
      prevTopics.map((topic) =>
        topic.topic_id === selectedTopicId ? { ...topic, questions: updatedQuestions } : topic
      )
    );

    if (updatedQuestions.length > 0) {
      removeDiscussionGuideError(selectedTopicId, "questions");
    }
  };

  const handleDeleteTopic = () => {
    pushToHistory(discussionGuide);
    const updatedTopics = discussionGuide.filter((topic) => topic.topic_id !== selectedTopicId);
    setDiscussionGuide(updatedTopics);
    setSelectedTopicId(updatedTopics.length > 0 ? updatedTopics[0].topic_id : null);
  };

  const handleDeleteQuestion = (index) => {
    pushToHistory(discussionGuide);
    const updatedQuestions = questions.filter((_, i) => i !== index);
    setQuestions(updatedQuestions);
    setDiscussionGuide((prevTopics) =>
      prevTopics.map((topic) =>
        topic.topic_id === selectedTopicId ? { ...topic, questions: updatedQuestions } : topic
      )
    );
  };

  const setSelectedTopicDepth = (depth) => {
    pushToHistory(discussionGuide);
    const updatedDiscussionGuide = discussionGuide.map((topic) => {
      if (topic.topic_id === selectedTopicId) {
        return { ...topic, depth_of_discussion: depth };
      }
      return topic;
    });
    setDiscussionGuide(updatedDiscussionGuide);
  };

  const handleTopicTitleChange = (e) => {
    pushToHistory(discussionGuide);
    const newTitle = e.target.value;
    setDiscussionGuide((prevTopics) =>
      prevTopics.map((topic) =>
        topic.topic_id === selectedTopicId ? { ...topic, topic_title: newTitle } : topic
      )
    );
    if (newTitle.trim() !== "") {
      removeDiscussionGuideError(selectedTopicId, "title");
    }
  };

  const handleContextChange = (e) => {
    pushToHistory(discussionGuide);
    const newContext = e.target.value;
    setDiscussionGuide((prevTopics) =>
      prevTopics.map((topic) =>
        topic.topic_id === selectedTopicId ? { ...topic, context: newContext } : topic
      )
    );
    if (newContext.trim() !== "") {
      removeDiscussionGuideError(selectedTopicId, "context");
    }
  };

  const handleAddQuestion = () => {
    pushToHistory(discussionGuide);
    const newQuestion = {
      id: null, // Indicating a new question
      question: "",
      question_label: "",
      order: 0
    };
    const updatedQuestions = [...questions, newQuestion];
    setQuestions(updatedQuestions);
    setDiscussionGuide((prevTopics) =>
      prevTopics.map((topic) =>
        topic.topic_id === selectedTopicId ? { ...topic, questions: updatedQuestions } : topic
      )
    );
    setEditIndex(updatedQuestions.length - 1); // Set the last question (newly added) into edit mode
    removeDiscussionGuideError(selectedTopicId, "questions");
  };
  

  const selectedTopic = Array.isArray(discussionGuide)
  ? discussionGuide.find((topic) => topic.topic_id === selectedTopicId) || null
  : null;

  const handleAddTopic = () => {
    pushToHistory(discussionGuide);
  
    const newTopic = {
      topic_id: `temp_${Date.now()}`, // Generate a temporary unique ID
      topic_title: "",
      depth_of_discussion: "normal",
      order: 0,
      context: "",
      questions: []
    };
  
    const updatedDiscussionGuide = [newTopic, ...discussionGuide];
    setDiscussionGuide(updatedDiscussionGuide);
    
    setSelectedTopicId(newTopic.topic_id); // Set the newly added topic as selected
    setQuestions(newTopic.questions); // Initialize the questions for the new topic
    setTimeout(() => {
      document.querySelector('.detail-topic-title')?.focus(); // Focus on the topic title input field
    }, 0);
  };
  

  const handleGenerateQuestions = async () => {
    try {
      setLoadingQuestions(true);
      if (!selectedTopic?.topic_title?.trim() || !selectedTopic?.context?.trim()) {
        return;  // disabled anyway, but double-check
      }
 
      const userContent = {
        topic_title: selectedTopic.topic_title,
        topic_context: selectedTopic.context,
        discussion_guide: discussionGuide
      };
 
      const response = await generateSingleTopicQuestions({
        getAccessTokenSilently,
        getIdTokenClaims,
        userContent
      });
      
      const newQuestionsFromLLM = JSON.parse(response.response);
      
      const newQuestionObjects = newQuestionsFromLLM.map((q) => ({
        question: q.question,
        question_label: q["question-label"],
        id: null
      }));

      pushToHistory(discussionGuide);

      setDiscussionGuide((prevTopics) =>
        prevTopics.map((topic) =>
          topic.topic_id === selectedTopicId
            ? { ...topic, questions: newQuestionObjects }
            : topic
        )
      );

      setQuestions(newQuestionObjects);
      removeDiscussionGuideError(selectedTopicId, "questions");
    } catch (error) {
      console.error("Error generating single topic questions:", error);
    }
    finally {
      setLoadingQuestions(false);
    }
  };

  const errorsForSelectedTopic =
  discussionGuideErrors[selectedTopic?.topic_id] || {};


  useEffect(() => {
    const numTopics = discussionGuide?.length || 0;
    const timeEstimate = calculateInterviewTime(numTopics);
    setExpectedInterviewTime(timeEstimate);
  }, [discussionGuide]);
  
  

  useEffect(() => {
    if (Array.isArray(discussionGuide) && discussionGuide.length > 0) {
      setSelectedTopicId((prevSelectedTopicId) => 
        prevSelectedTopicId || discussionGuide[0]?.topic_id
      );
    }
  }, [discussionGuide]);


  useEffect(() => {
    if (!selectedTopicId) return;
  
    const selectedTopic = Array.isArray(discussionGuide)
    ? discussionGuide.find((topic) => topic.topic_id === selectedTopicId) || null
    : null;

    if (selectedTopic) {
      setQuestions(selectedTopic.questions);
    }
  }, [selectedTopicId, discussionGuide]);

  useEffect(() => {
    if (selectedTopicId) {
      const selectedTopic = discussionGuide.find((topic) => topic.topic_id === selectedTopicId);
      if (selectedTopic) {
        setQuestions(selectedTopic.questions);
      }
    }
  }, [selectedTopicId, discussionGuide]);

  return (
    <>
          {isGeneratingDiscussionGuide || discussionGuide === null || discussionGuide.length === 0  ? (
        <div className="loader-container">
              <ProgressBar
                  progress={progress}
                  totalSteps={8} 
                  currentStep={progressStatus}
              />
          <ThreeDots color="#333" height={50} width={50} />
        </div>
      ) : (
        <div className='discussion-guide-step'>
        <div className="step-header">
            <div className="step-label">Review Discussion Guide</div>

          </div><div className="step-description">Based on the objective the list of topics have been created which should be discussed in the interview. Please review and feel free to edit them. </div><div className="questions-step-container">

              <div className="topics-section">
                {/* <div className='topic-section-header'>AI Finance App Research</div> */}
                <div className="eta-description">Expected Interview Time - {expectedInterviewTime}</div>

                <div className='topic-button-container'>
                <button className="add-question-button" onClick={handleAddTopic}>
                      <FaPlus className="add-icon-question" />
                      Add Topic
                    </button>
                    <button
                      className="undo-button"
                      onClick={handleUndo}
                      title="Undo the last action"
                      disabled={history.length === 0} // Disable if no history
                    >
                      <FaUndo className='undo-icon' />
                    </button>
                    <button
                      className="undo-button"
                      onClick={handleRedo}
                      title="Redo the last undone action"
                      disabled={redoStack.length === 0} // Disable if no redo stack
                    >
                      <FaRedo className="undo-icon" />
                    </button>


                    </div>
                    <DragDropContext
                    onDragEnd={(result) => {
                      if (!result.destination) return;
                      pushToHistory(discussionGuide);

                      const reorderedTopics = Array.from(discussionGuide);
                      const [movedTopic] = reorderedTopics.splice(result.source.index, 1);
                      reorderedTopics.splice(result.destination.index, 0, movedTopic);
                      reorderedTopics.forEach((topic, newIndex) => {
                        topic.order = newIndex;
                      });

                      setDiscussionGuide(reorderedTopics);
                      if (reorderedTopics.length > 0) {
                        setSelectedTopicId(reorderedTopics[0].topic_id);
                      }
                    }}
                  >
                <Droppable droppableId="topics">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {discussionGuide.map((topic, index) => {
                        const isSelected = topic.topic_id === selectedTopicId;
                        return (
                          <div className="topic-row" key={topic.topic_id}>
                            {/* Topic line remains fixed */}
                            <div className="topic-line">
                              <div
                                className={`circle ${
                                  isSelected ? 'selected-circle' : 'default-circle'
                                }`}
                              ></div>
                              {index < discussionGuide.length - 1 && <div className="vertical-line"></div>}
                            </div>

                            {/* Draggable wraps only the topic-block */}
                            <Draggable key={topic.topic_id} draggableId={topic.topic_id} index={index}>
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className={`topic-block ${isSelected ? 'selected' : ''}`}
                                  onClick={() => handleTopicClick(topic.topic_id)}
                                >
                                  <div className="topic-info">
                                    <div className="topic-header">
                                      <div className="topic-title">{topic.topic_title}</div>
                                      <div className="topic-depth">
                                        {topic.depth_of_discussion === 'high' ? (
                                          <img
                                            src={high_depth_grey}
                                            alt="High Depth"
                                            className="depth-icon"
                                            title="The interviewer will go deep on this topic"
                                          />
                                        ) : (
                                          <img
                                            src={normal_depth_grey}
                                            alt="Normal Depth"
                                            className="depth-icon"
                                            title="The interviewer will go to usual depth on this topic"
                                          />
                                        )}
                                      </div>
                                    </div>
                                    <ul className="question-labels">
                                      {topic.questions.map((q, qIndex) => (
                                        <li key={qIndex}>{q.question_label}</li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          </div>
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>


              </div>

              <div className="container"></div>

              <div className="topic-details-section">
                {selectedTopic && (
                  <>
                    <div className="detail-topic-header">
                      <input
                        type="text"
                        className="detail-topic-title"
                        placeholder='Add a title for the new topic'
                        value={selectedTopic.topic_title}
                        onChange={handleTopicTitleChange} />
                        {errorsForSelectedTopic.title && (
                            <div className="err-message">{errorsForSelectedTopic.title}</div>
                          )}
                      <button
                        className="delete-topic-button"
                        onClick={handleDeleteTopic}
                        title="Delete Topic"
                      >
                        <FaTrashAlt />
                      </button>
                    </div>

                    <div className="depth-section-title">Context</div>
                    <textarea
                      className="detail-context"
                      value={selectedTopic.context}
                      onChange={handleContextChange}
                      placeholder='Add context on why this topic is important to be included in the discussion. What areas should be discussed within this topic?'
                    ></textarea>
                              {errorsForSelectedTopic.context && (
                        <div className="err-message">{errorsForSelectedTopic.context}</div>
                      )}
                                        <button
                      className="add-question-button"
                      style={{ display: 'flex', alignItems: 'center' }}
                      onClick={handleGenerateQuestions}
                      // Enabled only if topic title and context are non-empty
                      disabled={
                        selectedTopic.topic_title?.trim() === "" || selectedTopic.context?.trim() === ""
                      }
                      title={
                        selectedTopic?.questions?.length === 0
                          ? "Generate questions for this topic"
                          : "Refresh questions for this topic"
                      }
                      
                    >
                      <FaMagic className="add-icon-question" /> {selectedTopic?.questions?.length === 0 ? "Generate Questions" : "Refresh Questions"}

                    </button>

                    <div className="depth-section">
                      <h3 className="depth-section-title">Level of followups</h3>
                      <div className="depth-options">
                        <label className={`depth-option ${selectedTopic.depth_of_discussion === 'normal' ? 'selected' : ''}`}>
                          <input
                            type="radio"
                            name="depth"
                            value="normal"
                            checked={selectedTopic.depth_of_discussion === 'normal'}
                            onChange={() => setSelectedTopicDepth('normal')} />
                          <img src={normal_depth_grey} alt="Normal Depth" className="depth-icon-option" />
                          <span className="depth-label">Low</span>
                        </label>
                        <label className={`depth-option ${selectedTopic.depth_of_discussion === 'high' ? 'selected' : ''}`}>
                          <input
                            type="radio"
                            name="depth"
                            value="high"
                            checked={selectedTopic.depth_of_discussion === 'high'}
                            onChange={() => setSelectedTopicDepth('high')} />
                          <img src={high_depth_grey} alt="High Depth" className="depth-icon-option" />
                          <span className="depth-label">High</span>
                        </label>
                      </div>
                    </div>

                    <div className="depth-section-title">Questions</div>

                    <div className='add-question-button-row'>
                    <button className="add-question-button" onClick={handleAddQuestion}>
                      <FaPlus className="add-icon-question" />
                      Add Question
                    </button>
                    </div>

                    {errorsForSelectedTopic.questions && (
            <div className="err-message">{errorsForSelectedTopic.questions}</div>
          )}

                    <DragDropContext onDragEnd={handleOnDragEnd}>
                      <Droppable droppableId="questions">
                        {(provided) => (
                          <div {...provided.droppableProps} ref={provided.innerRef} className="questions-list">
                                        {loadingQuestions ? (
                                  <ThreeDots color="#333" height={50} width={50} />
                                ) : (
                            questions.map((question, index) => (
                              <Draggable key={index} draggableId={`question-${index}`} index={index}>
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className="question-box"
                                  >
                                    {editIndex === index ? (
                                      <textarea
                                        value={question.question}
                                        placeholder='Add question'
                                        onChange={(e) => handleQuestionChange(index, e.target.value)}
                                        onBlur={() => setEditIndex(null)}
                                        autoFocus />
                                    ) : (
                                      <div className="question-content">
                                        <span>{question.question}</span>
                                        <div className="icons">
                                          <FaEdit onClick={() => setEditIndex(index)} />
                                          <FaTrashAlt onClick={() => handleDeleteQuestion(index)} />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </Draggable>
                            )))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>

                      
                    </DragDropContext>


                  </>
                )}
              </div>
            </div>
            </div>
      )
      }

    </>
  );
};

export default DiscussionGuideStep;
